import * as querystring from "querystring";
import * as _ from "lodash";

export const uniqArray = (value: any[]): any[] => {
  return Array.from(new Set(value));
};

// オブジェクト型のうち、値に配列を取る変数のうち、全ての配列が空かどうかをチェックする
export function checkEmptyObjectArray(value: object): boolean {
  let result = true;

  Object.keys(value).forEach(key => {
    if (Array.isArray(value[key]) && value[key].length > 0) {
      result = false;
    }
  });

  return result;
}

// 1000ごとにピリオドを入れた値を返す
export function numberWithSeparators(
  value: number | null,
  sep = ","
): string | null {
  if (value != null) {
    if (value.toString() === value.toLocaleString()) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    }
    return value.toLocaleString();
  }
  return null;
}

// 文字を一定の長さで切り取り、切り取った値は`...`などを表示する
export function truncateText(
  text: string,
  length: number,
  omission = "…"
): string {
  if (text.length > length) {
    return `${text.substr(0, length - omission.length)}${omission}`;
  }
  return text;
}

// 指定した桁数だけ0詰めする
export function zeroPadding(number, length) {
  return (Array(length).join("0") + number).slice(-length);
}

// ミリ秒ではないタイムスタンプを返す
export function currentTimeStamp() {
  return Math.floor(Date.now() / 1000);
}

// ~分前といった表記にして返す
export const timeAgo = (
  timestamp: number,
  appendAgo?: boolean,
  currentTime?: number
): string | null => {
  if (timestamp) {
    if (!currentTime) {
      currentTime = Date.now();
    }

    const diff = Math.round((currentTime - timestamp) / 1000);
    const appendString = appendAgo ? "前" : "";

    if (diff < 1) {
      return "今";
    } else if (diff < 60) {
      return `${diff}秒${appendString}`;
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)}分${appendString}`;
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)}時間${appendString}`;
    } else if (diff < 31536000) {
      return `${Math.floor(diff / 86400)}日${appendString}`;
    } else {
      return `${Math.floor(diff / 31536000)}年${appendString}`;
    }
  }
  return null;
};

// おおよその変数のサイズを返す
export function roughSizeOfObject(object): number {
  const objectList: any[] = [];
  const stack = [object];
  let bytes = 0;

  while (stack.length) {
    const value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (const i of Object.keys(value)) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
}

// URL形式を検証する
export function isValidURL(url: string): boolean {
  if (url != null) {
    return !!url.match(
      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
    );
  } else {
    return false;
  }
}

// メールアドレスの形式を検証する
export function isValidEmail(email: string): boolean {
  const mailRegex = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!email.match(mailRegex);
}

// タグを取り除いた文字列を返す
export function stripTag(text: string): string {
  return text.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, "");
}

// 日付が正しいかどうかを検証する
export function isValidDate(date) {
  return date instanceof Date && !isNaN(date.valueOf());
}

// カラーコードとして正しいか
export function isValidColorCode(color) {
  if (color.match(/^#?(?:[0-9a-fA-F]{3}){2}$/)) {
    return true;
  } else {
    return false;
  }
}

// HTMLのスケープを戻す
export function htmlDecode(input) {
  const e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

// string型の比較(nullと''を同じとみなす)
export function equalVal(a: string, b: string) {
  return _.eq(a || "", b || "");
}

// nullでなければ引数の値を出力
export function valueUnlessNull(value: any, defaultValue: any) {
  return value != null ? value : defaultValue;
}

// 数字かどうか
export function isNumberFormat(value: string) {
  return !!value.match(/^\d+$/);
}

// string型の場合のみtrimする
export function trimIfString(val: string) {
  if (typeof val === "string") {
    val = val.trim();
  }

  return val;
}

/**
 * 値が存在するかのチェック
 * @param val
 * @param includeZero trueならナンバー形式の場合、0を存在しないとみなす
 * @returns {boolean}
 */
export function isPresent(val: any, includeZero: boolean = true) {
  if (typeof val === "number") {
    return !isNaN(val) && (!includeZero || (includeZero && val !== 0));
  } else if (typeof val === "string") {
    return val.trim().length > 0;
  } else if (_.isArray(val)) {
    return val.length > 0;
  } else if (_.isPlainObject(val)) {
    return Object.keys(val).length > 0;
  } else {
    return false;
  }
}

/**
 * 値が空かどうかのチェック
 */
export function isBlank(val: any, includeZero: boolean = true) {
  return !isPresent(val, includeZero);
}

/**
 * キャピタライズを行う
 */
export function capitalize(value: string): string | null {
  if (value == null) {
    return null;
  }
  return value
    .split(/\s/)
    .map(v =>
      v.toLowerCase().replace(v.substr(0, 1), v.charAt(0).toUpperCase())
    )
    .join(" ");
}

/**
 * 値をnumber型に変換して返す
 */

export function numberCast(value: string): number | null {
  // 10進数の場合
  value = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 65248);
  });

  // 16進数の場合
  value = value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });

  const numValue = Number(value);
  return isNaN(numValue) ? null : numValue;
}

// inputやselectからの値をstring、numberに変換し、適切な値で返す
export function fixedInputValue(value: any, number: boolean): any {
  if (number) {
    const numValue = Number(value);
    if (isNaN(numValue)) {
      return null;
    } else {
      return numValue;
    }
  } else {
    return value;
  }
}

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
