import { nodeListToElements } from "../../common/utilsDom";

// loaderをElementの末尾に追加する
export function insertLoader(element: Element) {
  const loaderHTML = `<div class="module-loader-container"><div class="module-loader"></div></div>`;
  element.insertAdjacentHTML("beforeend", loaderHTML);
}

// loaderをElementから削除する
export function removeLoader(element: Element) {
  const nodeList = element.querySelectorAll(".module-loader-container");
  nodeListToElements(nodeList).forEach(node => {
    if (node.parentNode) {
      node.parentNode.removeChild(node);
    }
  });
}
