export const webRoutes = {
  home: (page: number) => {
    return `/?page=${page}`;
  },
  articleShow: (id: number) => {
    return `/articles/${id}`;
  },
  eventShow: (routing: string) => {
    return `/events/${routing}`;
  },
  fighterShow: (routes: any) => {
    return `/fighter/${routes}`;
  },
  movieIndex: (page?: number) => {
    return page ? `/movies?page=${page}` : `/movies`;
  }
};
