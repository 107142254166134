import axios from "axios";
import "lazysizes";
import * as Rails from "rails-ujs";
import { nodeListToElements } from "../common/utilsDom";
import { FacebookShareSchema } from "../common/schema";
import { uniqArray } from "../common/utils";

declare const window: any;

// 画像のlazyload
document.addEventListener("lazybeforeunveil", (e: any) => {
  const imageUrl = e.target.getAttribute("data-bg");
  if (imageUrl) {
    e.target.style.backgroundImage = `url('${imageUrl}')`;
  }
});

// facebookのシェアカウントを挿入
const insertFacebookShareCount = () => {
  // 各nodeごとに重複を除いたURLを取得して、それぞれajaxで結果を取得した後に、シェア数を描画する
  const elements = nodeListToElements(
    document.querySelectorAll(".fb-share-count[data-href]")
  );
  const urlList = uniqArray(
    elements.map(element => element.getAttribute("data-href"))
  );

  urlList.map(url => {
    const connectURL = `https://graph.facebook.com/?id=${url}`;
    axios.get(connectURL).then(res => {
      const shareCount = (res.data as any).share.share_count;
      const targetNodes = elements.filter(
        element => element.getAttribute("data-href") === url
      );
      targetNodes.forEach(element => {
        element.innerHTML = String(shareCount);
      });
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  insertFacebookShareCount();
});

// hot reloadする時に、既に変数が定義されているとrails-ujsからエラーが出され
// ページが強制リロードになるので、変数が定義されていばい場合のみstartする
if (!window._rails_loaded) {
  Rails.start();
}
