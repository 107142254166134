import {
  elementFromHTML,
  getClasses,
  insertBefore,
  removeElement
} from "../common/utilsDom";

let searchModalShown = false;

const mainViewElement = document.getElementById("main-view") as HTMLElement;
const searchModalElement = document.getElementById(
  "search-modal"
) as HTMLElement;

document.addEventListener("DOMContentLoaded", () => {
  (document.getElementById(
    "header-search-button"
  ) as HTMLElement).addEventListener("click", () => showModal());
  (document.getElementById(
    "close-search-modal-button"
  ) as HTMLElement).addEventListener("click", () => closeModal());
  manageFixedNavigation();
});

function showModal() {
  if (!searchModalShown) {
    searchModalElement.classList.add("show");
    searchModalElement.classList.remove("hide");
    mainViewElement.classList.add("blur");

    searchModalShown = true;
    document.addEventListener("keydown", keyListener);
  }
}

function closeModal() {
  if (searchModalShown) {
    searchModalElement.classList.remove("show");
    searchModalElement.classList.add("hide");
    setTimeout(() => {
      searchModalElement.classList.remove("hide");
    }, 200);
    mainViewElement.classList.remove("blur");

    searchModalShown = false;
    document.removeEventListener("keydown", keyListener);
  }
}

function keyListener(e: KeyboardEvent) {
  if (e.key === "Escape") {
    closeModal();
  }
}

// スクロールに応じてナビゲーションを固定する
function manageFixedNavigation() {
  // ヘッダーの高さを超えてスクロールしたときに、モバイルのナビゲーションにfixedクラスを付与する
  const headerElement = document.getElementById("global-header") as HTMLElement;
  const navigationElement = document.getElementById(
    "mobile-navigation"
  ) as HTMLElement;
  const fixedClass = "fixed";
  const dummyID = "mobile-navigation-dummy";

  // dev環境だとDOMが読み込まれた時点では高さが適切に設定されていないので一旦ゼロにしておく
  let headerHeight = 0;
  let navigationHeight = 0;

  const buildDummyElement = (height: number): Element => {
    return elementFromHTML(
      `<div id="${dummyID}" style="height: ${navigationHeight}px"></div>`
    );
  };

  document.addEventListener("scroll", () => {
    if (headerHeight === 0) {
      headerHeight = headerElement.clientHeight;
    }
    if (navigationHeight === 0) {
      navigationHeight = navigationElement.clientHeight;
    }

    const classes = getClasses(navigationElement);
    if (window.scrollY >= headerHeight) {
      if (!classes.includes(fixedClass)) {
        navigationElement.classList.add(fixedClass);

        // 高さを一定にするためダミーを挟んでおく
        insertBefore(buildDummyElement(navigationHeight), navigationElement);
      }
    } else {
      if (classes.includes(fixedClass)) {
        navigationElement.classList.remove(fixedClass);

        // ダミーを削除する
        const dummyElement = document.getElementById(dummyID);
        if (dummyElement) {
          removeElement(dummyElement);
        }
      }
    }
  });
}
