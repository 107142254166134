import Glide from "@glidejs/glide";
import { insertLoader, removeLoader } from "./common/action";
import HomeApi from "./api/HomeApi";
import { detectReachBottom } from "../common/utilsDom";
import { IS_DEVELOPMENT } from "./common/constants";

declare const window: any;

if (window.HomePageFlag) {
  let nextPageLoading = false;
  let hasNextPage = true;
  let currentPage = 1;

  const mainElement = document.getElementById("main") as HTMLElement;

  const triggerInfiniteScroll = async () => {
    if (!hasNextPage) {
      return;
    }

    if (detectReachBottom(mainElement, 400)) {
      if (!nextPageLoading) {
        const articleListElement = mainElement.querySelector(
          ".main-article-list:last-child ul"
        );
        if (!articleListElement) {
          return;
        }

        try {
          nextPageLoading = true;
          insertLoader(mainElement);

          const html = await HomeApi.index(currentPage + 1);
          articleListElement.insertAdjacentHTML("beforeend", html);

          if (html.length === 0) {
            hasNextPage = false;
          } else {
            currentPage++;
          }
        } catch {
          hasNextPage = false;
        } finally {
          nextPageLoading = false;
          removeLoader(mainElement);
        }
      }
    }
  };

  const triggerEventSlider = () => {
    setTimeout(
      () => {
        const glide = new Glide("#home-event-container", {
          type: "carousel",
          startAt: 1,
          perView: 3,
          gap: 20,
          rewind: false,
          focusAt: "center",
          swipeThreshold: 50,
          dragThreshold: 50,
          breakpoints: {
            991: {
              perView: 2.2
            },
            767: {
              perView: 1.4,
              gap: 20
            }
          }
        });
        glide.mount();
      },
      IS_DEVELOPMENT ? 2000 : 0
    );
  };

  document.addEventListener("DOMContentLoaded", () => {
    triggerEventSlider();

    window.addEventListener("scroll", () => {
      triggerInfiniteScroll();
    });
  });
}
