import { IS_DEVELOPMENT } from "./common/constants";

document.addEventListener("DOMContentLoaded", () => {
  centralizeAllElement();
});

const centralizeAllElement = () => {
  // development環境ではスタイルが動的に読み込まれるので、setTimeoutで待機する
  setTimeout(
    () => {
      // 属性が付与された要素を中央までスクロールさせる
      const elements = document.querySelectorAll("[data-auto-centralize=true]");
      elements.forEach(element => {
        if (element instanceof HTMLElement) {
          centralizeElement(element);
        }
      });
    },
    IS_DEVELOPMENT ? 1000 : 0
  );
};

// 横スクロール可能な要素を、真ん中のアイテムが中央に来るようにスクロールさせる
const centralizeElement = (element: HTMLElement) => {
  const centerToScroll = Math.max(
    0,
    (element.scrollWidth - element.offsetWidth) / 2
  );
  element.scroll(centerToScroll, 0);
};
