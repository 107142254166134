import axios from "axios";
import ready from "./api/ready";

// Railsのcstf-tokenをヘッダーに挿入する
const csrfHeader = {};

function setCSRFHeader() {
  const csrfToken = (document.querySelector("meta[name=csrf-token]") as any)
    .content;
  csrfHeader["X-CSRF-Token"] = csrfToken;
}

ready(setCSRFHeader);

export default function getState() {
  return axios.create({
    headers: csrfHeader,
    transformResponse: [
      data => {
        try {
          return JSON.parse(data);
        } catch (Exception) {
          return data;
        }
      }
    ]
  });
}
