document.addEventListener("DOMContentLoaded", () => {
  const scrollTopButton = document.getElementById("scroll-top-button");
  if (scrollTopButton) {
    scrollTopButton.addEventListener("click", () => scrollToTop(300));
  }
});

const currentScrollY = () => window.scrollY || window.pageYOffset;

const scrollToTop = scrollDuration => {
  const scrollStep = currentScrollY() / (scrollDuration / 15);
  const scrollInterval = setInterval(() => {
    if (currentScrollY() > 0) {
      window.scrollTo(0, currentScrollY() - scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};
