import "babel-polyfill";
import "../styles/web/application.scss"

// @ts-ignore
require.context("images", true, /\.(png|jpg|jpeg|svg)$/);

import "../gonkaku/web/all"

declare const module: any;
if (module.hot) {
  module.hot.accept()
}
