declare const twttr: any;
declare const instgrm: any;

// 要素の末端までスクロールしたかを判定
export function detectReachBottom(
  element: Element,
  margin: number = 100
): boolean {
  return (
    element.getBoundingClientRect().top * -1 + window.innerHeight + margin >
    element.getBoundingClientRect().height
  );
}

// Twitterウィジェットを再読み込みする
export const reloadTwitterWidget = () => {
  if (typeof twttr !== "undefined") {
    twttr.widgets.load(); // 動的に埋め込んだツイートを読み込む
  }
};

// Instagramウィジェットを再読み込みする
export const reloadInstagramWidget = () => {
  if (typeof instgrm !== "undefined") {
    instgrm.Embeds.process();
  }
};

export const getClasses = (element: HTMLElement): string[] => {
  return element.className.trim().split(" ");
};

export const elementFromHTML = (html: string): Element => {
  const element = document.createElement("div");
  element.innerHTML = html;
  return element.firstChild as Element;
};

export const removeElement = (element: Element) => {
  if (element.parentNode) {
    element.parentNode.removeChild(element);
  }
};

export const insertBefore = (
  insertElement: Element,
  targetElement: Element
) => {
  if (targetElement.parentElement) {
    targetElement.parentElement.insertBefore(insertElement, targetElement);
  }
};

// IEでNodeListをforEachするとエラーになるので配列にして返す
export const nodeListToElements = (
  nodeList: NodeListOf<Element>
): [Element] => {
  return Array.prototype.slice.call(nodeList, 0);
};
