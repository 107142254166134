import getState from "../../common/api";
import { webRoutes } from "../common/routes";

export default class MovieApi {
  static index(page: number): Promise<string> {
    return new Promise((resolve, reject) => {
      getState()
        .get(webRoutes.movieIndex(page))
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
