import {
  detectReachBottom,
  elementFromHTML,
  nodeListToElements,
  reloadInstagramWidget,
  reloadTwitterWidget,
  removeElement
} from "../../common/utilsDom";
import { insertLoader, removeLoader } from "../common/action";
import MovieApi from "../api/MovieApi";

const dateFieldElement = (date: string): Element => {
  return elementFromHTML(`<div class="date-field"><span>${date}</span></div>`);
};

export function movieProcessing() {
  let nextPageLoading = false;
  let hasNextPage = true;
  let currentPage = 1;

  const mainElement = document.getElementById("main") as HTMLElement;
  const movieListElement = mainElement.querySelector(
    ".movie-list-container:last-child"
  ) as Element;

  // 動画コンポーネントが並んでいる中、日付が変わったタイミングで`dateField`のHTML要素を挿入する
  const appendDateFields = () => {
    const dateTexts: string[] = [];

    // 既存の要素を全て削除
    const dateFields = nodeListToElements(
      mainElement.querySelectorAll(".date-field")
    );
    dateFields.forEach(node => {
      removeElement(node);
    });

    const movieElements = mainElement.querySelectorAll(".movie-list-item");
    for (let i = 0; i < movieElements.length; i++) {
      const date = (movieElements[i] as HTMLElement).dataset.date;
      if (date) {
        dateTexts.push(date);
      }
    }

    dateTexts.forEach((text, i) => {
      let prevText: string | null = null;
      if (i >= 1) {
        prevText = dateTexts[i - 1];
      }

      // 前の日付テキストと異なったタイミングで、HTMLを挿入する
      if (prevText !== text) {
        const dateField = dateFieldElement(text);
        const parentElement = movieElements[i].parentElement;
        if (parentElement) {
          parentElement.insertBefore(dateField, movieElements[i]);
        }
      }
    });
  };

  const triggerInfiniteScroll = async () => {
    if (!hasNextPage) {
      return;
    }

    if (detectReachBottom(mainElement, 400)) {
      if (!nextPageLoading) {
        try {
          nextPageLoading = true;
          insertLoader(mainElement);

          const html = await MovieApi.index(currentPage + 1);
          movieListElement.insertAdjacentHTML("beforeend", html);

          reloadTwitterWidget();
          reloadInstagramWidget();
          appendDateFields();

          if (html.length === 0) {
            hasNextPage = false;
          } else {
            currentPage++;
          }
        } catch {
          hasNextPage = false;
        } finally {
          nextPageLoading = false;
          removeLoader(mainElement);
        }
      }
    }
  };

  document.addEventListener("DOMContentLoaded", () => {
    appendDateFields();
    window.addEventListener("scroll", () => {
      triggerInfiniteScroll();
    });
  });
}
