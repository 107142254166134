import { webRoutes } from "../common/routes";

export function modalProcessing() {
  const suggestMessage = document.getElementById(
    "movie-suggest-message"
  ) as HTMLElement;

  const mainViewElement = document.getElementById("main-view") as HTMLElement;
  const modalElement = document.getElementById(
    "movie-suggest-modal"
  ) as HTMLElement;
  const closeButton = document.getElementById(
    "close-modal-modal-button"
  ) as HTMLElement;
  const formElement = modalElement.querySelector("form") as Element;

  let searchModalShown = false;

  function showModal() {
    if (!searchModalShown) {
      modalElement.classList.add("show");
      modalElement.classList.remove("hide");
      mainViewElement.classList.add("blur");

      searchModalShown = true;
      document.addEventListener("keydown", keyListener);
    }
  }

  function closeModal() {
    if (searchModalShown) {
      modalElement.classList.remove("show");
      modalElement.classList.add("hide");
      setTimeout(() => {
        modalElement.classList.remove("hide");
      }, 200);
      mainViewElement.classList.remove("blur");

      searchModalShown = false;
      document.removeEventListener("keydown", keyListener);
    }
  }

  function keyListener(e: KeyboardEvent) {
    if (e.key === "Escape") {
      closeModal();
    }
  }

  document.addEventListener("DOMContentLoaded", () => {
    suggestMessage.addEventListener("click", () => showModal());
    closeButton.addEventListener("click", () => closeModal());

    formElement.addEventListener("ajax:error", () => {
      alert(
        "送信に失敗しました。入力内容に誤りがあるか、サーバーにエラーが発生した可能性があります。"
      );
    });
    formElement.addEventListener("ajax:success", () => {
      location.href = webRoutes.movieIndex();
    });
  });
}
